import gql from 'graphql-tag';

import { buildQuery } from './utils';

const PeriodicTaskFields = gql`
  fragment AdminPeriodicTaskFields on PeriodicTask {
    id
    name
    lastRunAt
    enabled
    priority
    startTime
    totalRunCount
    dateChanged
  }
`;

const listQuery = gql`
  query allPeriodicTasks($page: Int, $perPage: Int) {
    items: allPeriodicTasks(page: $page, perPage: $perPage) {
      ...AdminPeriodicTaskFields
    }
    total: _allPeriodicTasksMeta(page: $page, perPage: $perPage) {
      count
      __typename
    }
  }
  ${PeriodicTaskFields}
`;

const singleItemQuery = gql`
  query PeriodicTask($id: UUID!) {
    data: PeriodicTask(id: $id) {
      ...AdminPeriodicTaskFields
    }
  }
  ${PeriodicTaskFields}
`;

export const periodicTasks = buildQuery({
  listQuery,
  singleItemQuery,
});
