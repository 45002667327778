import gql from 'graphql-tag';

import { buildQuery } from './utils';

const StintTaskFields = gql`
  fragment AdminStintTaskFields on StintTask {
    id
    name
    taskType
    totalNumberBusinessesPosted
    totalNumberPostedTask
    averageStudentRatingPosted
    averageBusinessRatingPosted
    topTips {
      name
      id
    }
  }
`;

const listQuery = gql`
  query allStintTasks(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintTaskFilter
  ) {
    items: allStintTasks(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintTaskFields
    }
    total: _allStintTasksMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${StintTaskFields}
`;

const singleItemQuery = gql`
  query StintTask($id: UUID!) {
    data: StintTask(id: $id) {
      ...AdminStintTaskFields
    }
  }
  ${StintTaskFields}
`;

const updateQuery = gql`
  mutation updateStintTask(
    $id: UUID!
    $name: String
    $taskType: String
    $topTips: [TaskTopTipInput]
  ) {
    data: updateStintTask(
      id: $id
      name: $name
      taskType: $taskType
      topTips: $topTips
    ) {
      ...AdminStintTaskFields
    }
  }
  ${StintTaskFields}
`;

const createQuery = gql`
  mutation createStintTask($name: String!, $taskType: String) {
    data: createStintTask(name: $name, taskType: $taskType) {
      ...AdminStintTaskFields
    }
  }
  ${StintTaskFields}
`;

export const tasks = buildQuery({
  listQuery,
  singleItemQuery,
  createQuery,
  updateQuery,
});
