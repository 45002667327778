import gql from 'graphql-tag';

import { buildQuery } from './utils';

const TopTipFields = gql`
  fragment AdminTopTipFields on TopTip {
    id
    name
    task_id
  }
`;

const listQuery = gql`
  query allTopTips(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: TopTipFilter
  ) {
    items: allTopTips(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminTopTipFields
    }
    total: _allTopTipsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${TopTipFields}
`;

const singleItemQuery = gql`
  query TopTip($id: UUID!) {
    data: TopTip(id: $id) {
      ...AdminTopTipFields
    }
  }
  ${TopTipFields}
`;

const updateQuery = gql`
  mutation updateTopTip($id: UUID!, $name: String) {
    data: updateTopTip(id: $id, name: $name) {
      ...AdminTopTipFields
    }
  }
  ${TopTipFields}
`;

const createQuery = gql`
  mutation createTopTip($name: String!, $isDefault: Boolean, $taskId: UUID) {
    data: createTopTip(name: $name, isDefault: $isDefault, taskId: $taskId) {
      ...AdminTopTipFields
    }
  }
  ${TopTipFields}
`;

export const topTips = buildQuery({
  listQuery,
  singleItemQuery,
  createQuery,
  updateQuery,
});
