import gql from 'graphql-tag';

import { buildQuery } from './utils';

const StintopediaFields = gql`
  fragment AdminStintopediaFields on Stintopedia {
    aboutUs
    bookedOnboardingMeeting
    business {
      id
      logo
      name
    }
    businessLogo
    characteristicIds
    donts
    dos
    emoji
    exceptionIds
    facebook
    generalPhoto1
    generalPhoto2
    generalPhoto3
    generalPhoto4
    id
    instagram
    internalNotes
    preferredLanguageIds
    lastEditBy {
      id
      email
      firstName
      lastName
    }
    lastEditTime
    linkedin
    photoOfEntrance
    prepVideoUrl
    qrCodeLocation
    teamStructure
    usingStintCorrectly
    website
    agreedTakePartInMarketingInitiatives
    agreedToReferFriendsIfFinancialMetricsImproved
    agreedToOperateStaffingRota
    accessToTransactionSalesData
    accessToRotaExamples
    accessToAnnualPlAccounts
    agreedToContinuePostingIfWeSeeDesireResults
    onboardingComplete
  }
`;

const listQuery = gql`
  query allStintopedias(
    $filter: StintopediaFilter
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: allStintopedias(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...AdminStintopediaFields
    }
    total: _allStintopediasMeta(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
      __typename
    }
  }
  ${StintopediaFields}
`;

const singleItemQuery = gql`
  query Stintopedia($id: UUID!) {
    data: Stintopedia(id: $id) {
      ...AdminStintopediaFields
    }
  }
  ${StintopediaFields}
`;

const updateQuery = gql`
  mutation updateStintopedia(
    $businessId: UUID!
    $aboutUs: String
    $accessToAnnualPlAccounts: Boolean
    $accessToRotaExamples: Boolean
    $accessToTransactionSalesData: Boolean
    $agreedTakePartInMarketingInitiatives: Boolean
    $agreedToContinuePostingIfWeSeeDesireResults: Boolean
    $agreedToOperateStaffingRota: Boolean
    $agreedToReferFriendsIfFinancialMetricsImproved: Boolean
    $businessLogo: String
    $characteristicIds: [UUID]
    $donts: [String]
    $dos: [String]
    $emoji: String
    $exceptionIds: [UUID]
    $facebook: String
    $generalPhoto1: String
    $generalPhoto2: String
    $generalPhoto3: String
    $generalPhoto4: String
    $instagram: String
    $internalNotes: String
    $linkedin: String
    $onboardingComplete: Boolean
    $photoOfEntrance: String
    $preferredLanguageIds: [UUID]
    $prepVideoUrl: String
    $qrCodeLocation: String
    $teamStructure: String
    $website: String
  ) {
    data: updateStintopedia(
      businessId: $businessId
      aboutUs: $aboutUs
      accessToAnnualPlAccounts: $accessToAnnualPlAccounts
      accessToRotaExamples: $accessToRotaExamples
      accessToTransactionSalesData: $accessToTransactionSalesData
      agreedTakePartInMarketingInitiatives: $agreedTakePartInMarketingInitiatives
      agreedToContinuePostingIfWeSeeDesireResults: $agreedToContinuePostingIfWeSeeDesireResults
      agreedToOperateStaffingRota: $agreedToOperateStaffingRota
      agreedToReferFriendsIfFinancialMetricsImproved: $agreedToReferFriendsIfFinancialMetricsImproved
      businessLogo: $businessLogo
      characteristicIds: $characteristicIds
      donts: $donts
      dos: $dos
      emoji: $emoji
      exceptionIds: $exceptionIds
      facebook: $facebook
      generalPhoto1: $generalPhoto1
      generalPhoto2: $generalPhoto2
      generalPhoto3: $generalPhoto3
      generalPhoto4: $generalPhoto4
      instagram: $instagram
      internalNotes: $internalNotes
      linkedin: $linkedin
      onboardingComplete: $onboardingComplete
      photoOfEntrance: $photoOfEntrance
      preferredLanguageIds: $preferredLanguageIds
      prepVideoUrl: $prepVideoUrl
      qrCodeLocation: $qrCodeLocation
      teamStructure: $teamStructure
      website: $website
    ) {
      ...AdminStintopediaFields
    }
  }
  ${StintopediaFields}
`;

export const stintopedias = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
});
