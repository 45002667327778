import gql from 'graphql-tag';

import { buildQuery } from './utils';

const CountryFields = gql`
  fragment AdminCountryFields on Country {
    id
    name
    shortCode
    regions
  }
`;

const listQuery = gql`
  query allCountries(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CountryFilter
  ) {
    items: allCountries(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminCountryFields
    }
    total: _allCountriesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${CountryFields}
`;

const singleItemQuery = gql`
  query Country($id: UUID!) {
    data: Country(id: $id) {
      ...AdminCountryFields
    }
  }
  ${CountryFields}
`;

const updateQuery = gql`
  mutation updateCountry(
    $id: UUID!
    $name: String
    $shortCode: String
    $regions: String
  ) {
    data: updateCountry(
      id: $id
      name: $name
      shortCode: $shortCode
      regions: $regions
    ) {
      ...AdminCountryFields
    }
  }
  ${CountryFields}
`;

const createQuery = gql`
  mutation createCountry(
    $name: String!
    $shortCode: String!
    $regions: String!
  ) {
    data: createCountry(name: $name, shortCode: $shortCode, regions: $regions) {
      ...AdminCountryFields
    }
  }
  ${CountryFields}
`;

export const countries = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
  createQuery,
});
