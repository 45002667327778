import gql from 'graphql-tag';

import { buildQuery } from './utils';

const StintJourneyFields = gql`
  fragment AdminStintJourneyFields on StintJourney {
    estimatedTimeOfArrival
    estimatedTimeOfDeparture
    id
    internalNotes
    business {
      id
      name
    }
    lateNote
    status
    statusResolved
    confirmationScreenAccepted
    studentInitialLocation {
      longitude
      latitude
    }
    isEtaBasedOnLatestLocation
    isGranularLocationTrackingEnabled
    usingNewLocationMicroservices
    stint {
      longitude
      latitude
      business_id
      checkInDate
      dateFrom
      differentStartTimeReasons
      id
      address {
        id
        name
      }
    }
    student_id
  }
`;

const listQuery = gql`
  query allStintJourneys(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintJourneyFilter
  ) {
    items: allStintJourneys(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintJourneyFields
    }
    total: _allStintJourneysMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${StintJourneyFields}
`;

const singleItemQuery = gql`
  query StintJourney($id: UUID!) {
    data: StintJourney(id: $id) {
      ...AdminStintJourneyFields
    }
  }
  ${StintJourneyFields}
`;

const updateQuery = gql`
  mutation updateStintJourney(
    $id: UUID!
    $estimatedTimeOfArrival: DateTime
    $estimatedTimeOfDeparture: DateTime
    $checkInDate: DateTime
    $differentStartTimeReasons: [DifferentStartTimeReason]
    $lateNote: String
    $internalNotes: String
    $statusResolved: Boolean
  ) {
    data: updateStintJourney(
      id: $id
      estimatedTimeOfArrival: $estimatedTimeOfArrival
      estimatedTimeOfDeparture: $estimatedTimeOfDeparture
      checkInDate: $checkInDate
      differentStartTimeReasons: $differentStartTimeReasons
      lateNote: $lateNote
      internalNotes: $internalNotes
      statusResolved: $statusResolved
    ) {
      ...AdminStintJourneyFields
    }
  }
  ${StintJourneyFields}
`;

const updateManyQuery = gql`
  mutation resolveStintJourneys($ids: [UUID!]!) {
    data: resolveStintJourneys(stintJourneyIds: $ids) {
      ...AdminStintJourneyFields
    }
  }
  ${StintJourneyFields}
`;

export const stintJourneys = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
  updateManyQuery,
});
