import 'emoji-mart/css/emoji-mart.css';

import React, { FC, useEffect, useState } from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import buildGraphQLProvider from 'ra-data-graphql';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { Admin as RAdmin, DataProvider, Loading } from 'react-admin';

import { useApolloClient } from 'apollo';
import { useAuthProvider } from 'utils';

import { buildQuery } from '../graphql-resources';
import { __schema as schema } from '../introspection.json';
import { AuthResource } from './AuthResource';
import { Layout } from './Layout';
import { LoginPage } from './LoginPage';
import * as addresses from './models/Address';
import * as appeal from './models/Appeal';
import * as businesses from './models/Business';
import * as characteristic from './models/Characteristic';
import * as city from './models/City';
import * as countries from './models/Country';
import * as deviceLocations from './models/DeviceLocation';
import * as editableSettings from './models/EditableSetting';
import * as employer from './models/Employer';
import * as exceptions from './models/EmploymentException';
import * as geotracking from './models/Geotracking';
import * as languages from './models/Language';
import * as manageSurgeList from './models/ManageSurgeList';
import * as notifications from './models/Notification';
import * as offence from './models/Offence';
import * as periodicTasks from './models/PeriodicTask';
import * as postalCodes from './models/PostalCode';
import * as regions from './models/Region';
import * as staff from './models/Staff';
import * as stintopedias from './models/Stintopedia';
import * as stints from './models/Stints';
import * as student from './models/Student';
import * as studentReviews from './models/StudentReviews';
import * as tags from './models/Tags';
import * as tasks from './models/Task';
import * as topTips from './models/TopTip';
import * as university from './models/University';
import * as users from './models/Users';

// Set allowMissing to true so that Polyglot won't throw an error on missing translations.
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
  allowMissing: true,
});

export const Admin: FC = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider>();
  const client = useApolloClient();
  const customAuthProvider = useAuthProvider('stint-admin');

  useEffect(() => {
    if (dataProvider) return;
    (async () => {
      const LazyPageCursor = {};
      const provider = await buildGraphQLProvider({
        introspection: { schema },
        buildQuery: buildQuery(LazyPageCursor),
        client,
      });

      setDataProvider(() => provider);
    })();
  }, [client, dataProvider]);

  if (!dataProvider) {
    return (
      <>
        <br />
        <Loading
          loadingPrimary="Welcome"
          loadingSecondary="Your session is initialising..."
        />
      </>
    );
  }

  return (
    <ApolloProvider client={client}>
      <RAdmin
        authProvider={customAuthProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={LoginPage}
      >
        <AuthResource name="Stint" {...stints} />
        <AuthResource
          name="StintUser"
          {...users}
          options={{ label: 'Users' }}
        />
        <AuthResource name="Staff" {...staff} />
        <AuthResource name="Employer" {...employer} />
        <AuthResource name="Student" {...student} />
        <AuthResource name="Business" {...businesses} />
        <AuthResource name="StintJourney" {...geotracking} />
        <AuthResource name="Offence" {...offence} />
        <AuthResource name="Appeal" {...appeal} />
        <AuthResource name="City" {...city} />
        <AuthResource name="Country" {...countries} />
        <AuthResource name="Region" {...regions} />
        <AuthResource
          name="PostalCode"
          {...postalCodes}
          options={{ label: 'Postal codes' }}
        />
        <AuthResource
          name="StintTask"
          {...tasks}
          options={{ label: 'Tasks' }}
        />
        <AuthResource
          name="TopTip"
          {...topTips}
          options={{ label: 'Top tips' }}
        />
        <AuthResource name="Tag" {...tags} />
        <AuthResource name="University" {...university} />
        <AuthResource name="Address" {...addresses} />
        <AuthResource
          name="EditableSetting"
          {...editableSettings}
          options={{ label: 'Editable Settings' }}
        />
        <AuthResource name="ManageSurgeList" {...manageSurgeList} />
        <AuthResource
          name="EmploymentException"
          {...exceptions}
          options={{ label: 'Exceptions' }}
        />
        <AuthResource
          name="Notification"
          {...notifications}
          options={{ label: 'Notifications' }}
        />
        <AuthResource
          name="Stintopedia"
          {...stintopedias}
          options={{ label: 'Stintopedia' }}
        />
        <AuthResource name="Language" {...languages} />
        <AuthResource name="Characteristic" {...characteristic} />
        <AuthResource name="PeriodicTask" {...periodicTasks} />
        <AuthResource name="DeviceLocation" {...deviceLocations} />
        <AuthResource name="StudentReview" {...studentReviews} />
      </RAdmin>
    </ApolloProvider>
  );
};
