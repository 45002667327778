/**
 * This file handles the graphql queries and mutations for the StintTemplate
 * resource in the Scheduling app.
 * The resource  has been named SchedulingStintTemplate to avoid conflicting with the
 * existing list StintTemplate resource which is used on the Stint Types page
 * defined in src/graphql-resources/stintTemplate.ts
 */
import gql from 'graphql-tag';

import { buildQuery, singleQueryPaginationListResponse } from './utils';

const listQuery = gql`
  query ListStintTemplates($businessId: UUID!, $page: Int, $pageSize: Int) {
    data: stintTemplates(
      businessId: $businessId
      page: $page
      pageSize: $pageSize
    ) {
      objects {
        id
        name
      }
    }
  }
`;

export const schedulingStintTemplate = buildQuery({
  listQuery,
  listParseResponse: singleQueryPaginationListResponse,
});
