import gql from 'graphql-tag';

import { buildQuery } from './utils';

const TagFields = gql`
  fragment AdminTagFields on Tag {
    id
    name
    description
    createdAt
  }
`;

const listQuery = gql`
  query allTags(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
  ) {
    items: allTags(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...AdminTagFields
    }
    total: _allTagsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      count
      __typename
    }
  }
  ${TagFields}
`;

const singleItemQuery = gql`
  query Tag($id: UUID!) {
    data: Tag(id: $id) {
      ...AdminTagFields
    }
  }
  ${TagFields}
`;

const createQuery = gql`
  mutation createTag($name: String!, $description: String) {
    data: createTag(name: $name, description: $description) {
      ...AdminTagFields
    }
  }
  ${TagFields}
`;

const updateQuery = gql`
  mutation updateTag($id: UUID!, $name: String!, $description: String) {
    data: updateTag(id: $id, name: $name, description: $description) {
      ...AdminTagFields
    }
  }
  ${TagFields}
`;

export const tags = buildQuery({
  listQuery,
  singleItemQuery,
  createQuery,
  updateQuery,
});
