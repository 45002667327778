import gql from 'graphql-tag';

import { buildQuery } from './utils';

const StintUserFields = gql`
  fragment AdminStintUserFields on StintUser {
    id
    firstName
    lastName
    locationSetting
    userType
    fullname
    profilePhotoUrl
    phoneNumber
    deactivated
    isVerifiedByStint
    isEmailVerified
    otp
    city {
      name
    }
    currentCity {
      name
      id
    }
    permanentDeactivated
    email
    secondaryEmail
    appVersion
    dateOfBirth
    isStudent
    exceptions {
      id
      name
    }
    isEmployer
    studentProfile {
      id
      university_id
      emergencyContactName
      emergencyContactNumber
      emergencyContactRelation
      employeeStatement
      studentLoanPlan
      strikesAwarded
      paymentMethods {
        accountName
        accountNumber
        sortCode
      }
    }
    publicRating
    canSeeSurge
  }
`;

const listQuery = gql`
  query allStudentOrEmployerUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintUserFilter
  ) {
    items: allStudentOrEmployerUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      firstName
      lastName
      fullname
      email
    }
    total: _allStudentOrEmployerUsersMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;

const singleItemQuery = gql`
  query StintUser($id: UUID!) {
    mono: StintUser(id: $id) {
      ...AdminStintUserFields
    }
  }
  ${StintUserFields}
`;

export const updateQuery = gql`
  mutation updateStintUser(
    $id: UUID!
    $firstName: String
    $lastName: String
    $dateOfBirth: Date
    $email: String
    $secondaryEmail: String
    $exceptions: [StintTemplateExceptionInput]
    $phoneNumber: String
    $deactivated: Boolean
    $permanentDeactivated: Boolean
    $studentProfile: StudentProfileInput
    $paymentMethod: PaymentMethodInput
  ) {
    data: updateStintUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      email: $email
      secondaryEmail: $secondaryEmail
      exceptions: $exceptions
      phoneNumber: $phoneNumber
      deactivated: $deactivated
      permanentDeactivated: $permanentDeactivated
      studentProfile: $studentProfile
      paymentMethod: $paymentMethod
    ) {
      ...AdminStintUserFields
    }
  }
  ${StintUserFields}
`;

const singleItemParseResponse = (response: any) => {
  return {
    data: { ...response.data.mono },
  };
};
export const users = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
  singleItemParseResponse,
});
