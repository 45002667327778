import gql from 'graphql-tag';

import { buildQuery } from './utils';

const NotificationFields = gql`
  fragment AdminNotificationFields on Notification {
    id
    createdAt
    status
    errorMessage
    title
    message
    stint {
      id
      name
    }
    business {
      id
      name
    }
    sendTextMessage
    sendPushNotification
    recipientsCount
    openedCount
    notificationType
  }
`;

const listQuery = gql`
  query allNotifications(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: NotificationFilter
  ) {
    items: allNotifications(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminNotificationFields
    }
    total: _allNotificationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${NotificationFields}
`;

const singleItemQuery = gql`
  query Notification($id: UUID!) {
    data: Notification(id: $id) {
      ...AdminNotificationFields
    }
  }
  ${NotificationFields}
`;

const createQuery = gql`
  mutation createNotification(
    $title: String!
    $message: String!
    $stint_id: UUID
    $student_ids: [String]!
    $sendPushNotification: Boolean
  ) {
    data: createNotification(
      title: $title
      message: $message
      sendPushNotification: $sendPushNotification
      student_ids: $student_ids
      stint_id: $stint_id
    ) {
      ...AdminNotificationFields
    }
  }
  ${NotificationFields}
`;

export const notifications = buildQuery({
  listQuery,
  singleItemQuery,
  createQuery,
});
