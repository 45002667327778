import gql from 'graphql-tag';

import { buildQuery } from './utils';

const CityFields = gql`
  fragment AdminCityFields on City {
    baseFee
    baseFeeStudent
    name
    id
    isActive
    isAvailabilityEnabled
    sessionUrl
    shortCode
    state
    postalCodeIds
    region {
      name
      id
      country {
        name
        id
      }
    }
    regionId
  }
`;

const listQuery = gql`
  query allCities(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CityFilter
  ) {
    items: allCities(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminCityFields
    }
    total: _allCitiesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${CityFields}
`;

const singleItemQuery = gql`
  query City($id: UUID!) {
    data: City(id: $id) {
      ...AdminCityFields
    }
  }
  ${CityFields}
`;

const createQuery = gql`
  mutation createCity(
    $baseFee: Float!
    $baseFeeStudent: Float!
    $name: String!
    $regionId: UUID!
    $sessionUrl: URL
    $shortCode: String!
  ) {
    data: createCity(
      baseFee: $baseFee
      baseFeeStudent: $baseFeeStudent
      name: $name
      regionId: $regionId
      sessionUrl: $sessionUrl
      shortCode: $shortCode
    ) {
      ...AdminCityFields
    }
  }
  ${CityFields}
`;

const updateQuery = gql`
  mutation updateCity(
    $baseFee: Float!
    $baseFeeStudent: Float!
    $id: UUID!
    $isActive: Boolean
    $isAvailabilityEnabled: Boolean
    $sessionUrl: URL
    $name: String
    $regionId: UUID
    $shortCode: String
    $postalCodeIds: [UUID]
  ) {
    data: updateCity(
      baseFee: $baseFee
      baseFeeStudent: $baseFeeStudent
      id: $id
      isActive: $isActive
      isAvailabilityEnabled: $isAvailabilityEnabled
      sessionUrl: $sessionUrl
      shortCode: $shortCode
      name: $name
      regionId: $regionId
      postalCodeIds: $postalCodeIds
    ) {
      ...AdminCityFields
    }
  }
  ${CityFields}
`;

export const cities = buildQuery({
  createQuery,
  listQuery,
  singleItemQuery,
  updateQuery,
});
