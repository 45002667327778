import gql from 'graphql-tag';

import { buildQuery } from './utils';

const AddressFields = gql`
  fragment AdminAddressFields on Address {
    id
    name
    placeId
  }
`;

const listQuery = gql`
  query allAddresses(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AddressFilter
  ) {
    items: allAddresses(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminAddressFields
    }
    total: _allAddressesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${AddressFields}
`;

const singleItemQuery = gql`
  query Address($id: UUID!) {
    data: Address(id: $id) {
      ...AdminAddressFields
    }
  }
  ${AddressFields}
`;

export const addresses = buildQuery({
  listQuery,
  singleItemQuery,
});
