import React, { FC, useState } from 'react';

import Button from '@material-ui/core/Button';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  EditProps,
  FormDataConsumer,
  NullableBooleanInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  FunctionField,
  Record,
  SelectField,
} from 'react-admin';
import styled from 'styled-components';

import {
  EditTemplate,
  Heading,
  Label,
  SearchAddressField,
  SelectUserInput,
  TextField as TextFieldComponent,
} from 'components';
import { colors } from 'styles';
import {
  getNullableBooleanDisplayValue,
  STINT_STATUS,
  STINT_STATUS_CHOICES,
  UNASSIGNED_CHOICES,
  UNIFORM,
  validateUniform,
} from 'utils';

import { EditAsideStintActions } from '../EditAsideStintActions';
import { CustomToolbar } from '../StintEditToolbar';
import { STINT_IS_BEST_EFFORT } from '../utils';

const InvisibleBox = styled.div`
  display: none;
`;

const transform = (data: any) => {
  const variables = {
    ...data,
    primaryTasks: data.primaryTasks.map((id: string) => ({ id })),
    exceptions: data.exceptions.map((id: string) => ({ id })),
    cancellationFeeApplied: data.stintRates.cancellationFeeApplied,
    baseFeeOverride:
      data.stintRates.baseFeeOverride != null
        ? parseFloat(data.stintRates.baseFeeOverride)
        : null,
    baseRateOverride:
      data.stintRates.baseRateOverride != null
        ? parseFloat(data.stintRates.baseRateOverride)
        : null,
    surgeRate:
      data.stintRates.surgeRate != null
        ? parseFloat(data.stintRates.surgeRate)
        : null,
  };
  if (data.status !== STINT_STATUS.CANCELLED) {
    delete variables.cancellationFeeApplied;
    delete variables.status;
  }
  return variables;
};

const transformRecord = (record: any) => {
  return {
    ...record,
    exceptions: record?.exceptions?.map((exception: any) => exception.id),
    primaryTasks: record?.primaryTasks?.map((task: any) => task.id),
  };
};

export const StintEdit: FC<EditProps> = props => {
  const [showUnassignReasonSelect, setShowUnassignReasonSelect] = useState(
    false,
  );

  return (
    <EditTemplate
      {...props}
      aside={<EditAsideStintActions />}
      transform={transform}
      transformRecord={transformRecord}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <HeadingContainer>
          <Heading size={16} labelText="Stint Details:" />
        </HeadingContainer>
        <TextField source="id" />
        <TextInput source="name" validate={required()} />
        <SelectField
          choices={STINT_STATUS_CHOICES}
          label="Status"
          source="status"
        />
        <DateTimeInput alwaysOn label="Start date" source="dateFrom" />
        <DateTimeInput alwaysOn label="End date" source="dateTo" />
        <FormDataConsumer>
          {({ formData }: any) => {
            if (formData?.actualLeaveTime == null) {
              return null;
            }
            const actualLeaveTime = new Date(
              formData?.actualLeaveTime,
            ).toLocaleTimeString('en-GB', {
              hour: 'numeric',
              minute: 'numeric',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            });
            return (
              <TextFieldComponent
                label={`Actual leave time has been logged for ${actualLeaveTime}.`}
              />
            );
          }}
        </FormDataConsumer>
        <FunctionField
          data-testid="trained"
          addLabel={false}
          render={(record?: Record) => {
            const value = getNullableBooleanDisplayValue(record?.trained);
            return <TextFieldComponent label="Trained" value={value} />;
          }}
        />
        <NullableBooleanInput label="Control check" source="controlCheck" />
        <SelectInput
          alwaysOn
          choices={STINT_IS_BEST_EFFORT}
          data-testid="stint-best-effort"
          label="Best Effort"
          source="isBestEffort"
        />
        <TextInput label="Special Notes" source="notes" />
        <FormDataConsumer>
          {() => {
            return (
              <ReferenceArrayInput
                label="Tasks"
                perPage={100}
                reference="StintTask"
                source="primaryTasks"
                validate={required()}
              >
                <AutocompleteArrayInput optionText="name" suggestionLimit={5} />
              </ReferenceArrayInput>
            );
          }}
        </FormDataConsumer>

        <ReferenceArrayInput
          label="Exceptions"
          perPage={400}
          reference="EmploymentException"
          source="exceptions"
        >
          <AutocompleteArrayInput optionText="name" suggestionLimit={5} />
        </ReferenceArrayInput>

        <AutocompleteArrayInput
          choices={UNIFORM}
          optionText="name"
          source="uniform"
          translateChoice={false}
          validate={validateUniform}
        />

        <FunctionField
          addLabel={false}
          render={(record?: Record) => {
            return (
              <SelectUserInput
                label="Stinter"
                disabled={
                  record?.student_id || record?.status !== STINT_STATUS.POSTED
                }
                source="student_id"
              />
            );
          }}
        />
        <FunctionField
          addLabel={false}
          render={(record?: Record) =>
            record?.student_id &&
            record?.status === STINT_STATUS.ACCEPTED && (
              <UnassignButton
                disabled={showUnassignReasonSelect}
                onClick={() => setShowUnassignReasonSelect(true)}
              >
                Unassign Stinter
              </UnassignButton>
            )
          }
        />

        <FormDataConsumer>
          {({ formData }: any) =>
            formData.student_id &&
            formData.status === STINT_STATUS.ACCEPTED &&
            showUnassignReasonSelect && (
              <>
                <Label labelText="Please select a reason to finish unassigning the Stinter." />
                <SelectInput
                  choices={UNASSIGNED_CHOICES}
                  label="Unassign Reason"
                  source="studentUnassignedReasons"
                />
              </>
            )
          }
        </FormDataConsumer>

        <FunctionField
          addLabel={false}
          render={(record?: Record) =>
            record?.student_id &&
            record?.status !== STINT_STATUS.CANCELLED && (
              <DateTimeInput
                label="Check In Time"
                resource="StintJourney"
                source="checkInDate"
              />
            )
          }
        />

        <Label labelText="Site Address" />
        <FormDataConsumer>
          {({ formData }: any) => {
            return (
              <>
                <SearchAddressField
                  placeholder={formData.displayAddress}
                  source="displayAddress"
                  {...formData}
                />
                <InvisibleBox>
                  {formData?.placeId && (
                    <TextInput label="Place Id" source="placeId" />
                  )}
                </InvisibleBox>
              </>
            );
          }}
        </FormDataConsumer>

        <HeadingContainer>
          <Heading size={16} labelText="Site Monetary Values:" />
        </HeadingContainer>
        <TextField
          data-testid="total-fee-with-vat"
          label="Site Total Fee With VAT"
          source="stintRates.totalFeeWithVat"
        />
        <NumberInput
          data-testid="base-fee-override"
          label="Site Hourly Fee Override"
          source="stintRates.baseFeeOverride"
        />
        <FunctionField
          data-testid="cancellation-fee"
          addLabel={false}
          render={(record?: Record) => {
            const value = record?.stintRates?.cancellationFee ?? 'N/A';
            return (
              <TextFieldComponent label="Cancellation Fee" value={value} />
            );
          }}
        />
        <FormDataConsumer>
          {({ formData }: any) => {
            if (formData?.status !== STINT_STATUS.CANCELLED) {
              return null;
            }
            return (
              <BooleanInput
                label="Cancellation Fee Overridden"
                source="stintRates.cancellationFeeApplied"
                helperText="Flicking the switch overrides the cancellation fee"
              />
            );
          }}
        </FormDataConsumer>

        <HeadingContainer>
          <Heading size={16} labelText="Stinter Monetary Values:" />
        </HeadingContainer>
        <TextField
          data-testid="base-pay"
          label="Stinter Base Pay"
          source="stintRates.basePay"
        />
        <NumberInput
          data-testid="base-rate-override"
          label="Stinter Base Hourly Rate Override"
          source="stintRates.baseRateOverride"
        />
        <FunctionField
          data-testid="surge-pay"
          addLabel={false}
          render={(record?: Record) => {
            const value = record?.stintRates?.surgePay ?? 'N/A';
            return (
              <TextFieldComponent label="Stinter Surge Pay" value={value} />
            );
          }}
        />
        <NumberInput
          data-testid="surge-rate"
          label="Stinter Hourly Surge Rate"
          source="stintRates.surgeRate"
        />
        <FunctionField
          data-testid="surge-applied"
          addLabel={false}
          render={(record?: Record) => {
            const value = getNullableBooleanDisplayValue(
              record?.stintRates?.surgeApplied,
            );
            return (
              <TextFieldComponent label="Surge Pay Applied" value={value} />
            );
          }}
        />
        <FunctionField
          data-testid="bonus-pay"
          addLabel={false}
          render={(record?: Record) => {
            const value = record?.stintRates?.bonusPay ?? 'N/A';
            return (
              <TextFieldComponent label="Stinter Boost Pay" value={value} />
            );
          }}
        />
        <FunctionField
          data-testid="bonus-rate"
          addLabel={false}
          render={(record?: Record) => {
            const value = record?.stintRates?.bonusRate ?? 'N/A';
            return (
              <TextFieldComponent
                label="Stinter Hourly Boost Rate"
                value={value}
              />
            );
          }}
        />
        <FunctionField
          data-testid="bonus-applied"
          addLabel={false}
          render={(record?: Record) => {
            const value = getNullableBooleanDisplayValue(
              record?.stintRates?.bonusApplied,
            );
            return (
              <TextFieldComponent label="Boost Pay Applied" value={value} />
            );
          }}
        />
      </SimpleForm>
    </EditTemplate>
  );
};

const UnassignButton = styled(Button)`
  border: solid 2px
    ${props => (props.disabled ? colors.neutralLightest60 : colors.secondary)};
  bottom: 12px;
`;

const HeadingContainer = styled.div`
  margin-bottom: 12px;
  margin-top: 24px;
`;
