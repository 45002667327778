import gql from 'graphql-tag';

import { buildQuery } from './utils';

const LanguageFields = gql`
  fragment AdminLanguageFields on Language {
    id
    name
  }
`;

const listQuery = gql`
  query allLanguages(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: LanguageFilter
  ) {
    items: allLanguages(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminLanguageFields
    }
    total: _allLanguagesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${LanguageFields}
`;

const singleItemQuery = gql`
  query Language($id: UUID!) {
    data: Language(id: $id) {
      ...AdminLanguageFields
    }
  }
  ${LanguageFields}
`;

export const languages = buildQuery({
  listQuery,
  singleItemQuery,
});
