import gql from 'graphql-tag';

import { buildQuery } from './utils';

const SingleStintTemplateFields = gql`
  fragment AdminSingleStintTemplateFields on StintTemplate {
    id
    name
    business_id
    notes
    uniform
    primaryTasks {
      id
      name
    }
    secondaryTasks {
      id
      name
    }
    exceptions {
      id
      name
    }
    prepVideoUrl
    trained
  }
`;

const StintTemplateFields = gql`
  fragment AdminStintTemplateFields on StintTemplate {
    id
    name
    business_id
    notes
    uniform
    primaryTasks {
      id
      name
    }
    secondaryTasks {
      id
      name
    }
    exceptions {
      id
      name
    }
    prepVideoUrl
    trained
  }
`;

const listQuery = gql`
  query allStintTemplates(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StintTemplateFilter
  ) {
    items: allStintTemplates(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminStintTemplateFields
    }
    total: _allStintTemplatesMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${StintTemplateFields}
`;

const singleItemQuery = gql`
  query StintTemplate($id: UUID!) {
    data: StintTemplate(id: $id) {
      ...AdminSingleStintTemplateFields
    }
  }
  ${SingleStintTemplateFields}
`;

const updateQuery = gql`
  mutation updateStintTemplate($input: UpdateStintTemplateInput!) {
    data: updateStintTemplate(input: $input) {
      ...AdminSingleStintTemplateFields
    }
  }
  ${SingleStintTemplateFields}
`;

export const stintTemplates = buildQuery({
  listQuery,
  singleItemQuery,
  updateQuery,
});
