import gql from 'graphql-tag';

import { buildQuery } from './utils';

const StudentReviewFields = gql`
  fragment AdminStudentReviewFields on StudentReview {
    id
    stint {
      name
      status
      dateFrom
      dateTo
      business_id
    }
    student_id
    rating
    isInternalReview
  }
`;

const listQuery = gql`
  query allStudentReviews(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: StudentReviewFilter
  ) {
    items: allStudentReviews(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      __typename
      ...AdminStudentReviewFields
    }
    total: _allStudentReviewsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${StudentReviewFields}
`;

const singleItemQuery = gql`
  query StudentReview($id: UUID!) {
    data: StudentReview(id: $id) {
      __typename
      ...AdminStudentReviewFields
    }
  }
  ${StudentReviewFields}
`;

export const studentReviews = buildQuery({
  listQuery,
  singleItemQuery,
});
