import gql from 'graphql-tag';

import { buildQuery } from './utils';

const EmploymentExceptionFields = gql`
  fragment AdminEmploymentExceptionFields on EmploymentException {
    id
    name
    category
  }
`;

const listQuery = gql`
  query allEmploymentExceptions(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: EmploymentExceptionFilter
  ) {
    items: allEmploymentExceptions(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminEmploymentExceptionFields
    }
    total: _allEmploymentExceptionsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${EmploymentExceptionFields}
`;

const singleItemQuery = gql`
  query EmploymentException($id: UUID!) {
    data: EmploymentException(id: $id) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${EmploymentExceptionFields}
`;

const createQuery = gql`
  mutation createEmploymentException(
    $name: String!
    $category: EmploymentExceptionCategoryInputType
  ) {
    data: createEmploymentException(name: $name, category: $category) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${EmploymentExceptionFields}
`;

const updateQuery = gql`
  mutation updateEmploymentException(
    $id: UUID!
    $name: String!
    $category: EmploymentExceptionCategoryInputType
  ) {
    data: updateEmploymentException(id: $id, name: $name, category: $category) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${EmploymentExceptionFields}
`;

const deleteQuery = gql`
  mutation deleteEmploymentException($id: UUID!) {
    data: deleteEmploymentException(id: $id) {
      ...AdminEmploymentExceptionFields
    }
  }
  ${EmploymentExceptionFields}
`;

export const employmentExceptions = buildQuery({
  listQuery,
  singleItemQuery,
  createQuery,
  updateQuery,
  deleteQuery,
});
