import gql from 'graphql-tag';

import { buildQuery } from './utils';

const RegionFields = gql`
  fragment AdminRegionFields on Region {
    id
    name
    country {
      name
      id
    }
    countryId
  }
`;

const listQuery = gql`
  query allRegions(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: RegionFilter
  ) {
    items: allRegions(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...AdminRegionFields
    }
    total: _allRegionsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
  ${RegionFields}
`;

const singleItemQuery = gql`
  query Region($id: UUID!) {
    data: Region(id: $id) {
      ...AdminRegionFields
    }
  }
  ${RegionFields}
`;

const createQuery = gql`
  mutation createRegion($name: String!, $countryId: UUID!) {
    data: createRegion(name: $name, countryId: $countryId) {
      ...AdminRegionFields
    }
  }
  ${RegionFields}
`;

const updateQuery = gql`
  mutation updateRegion($id: UUID!, $name: String!, $countryId: UUID) {
    data: updateRegion(id: $id, name: $name, countryId: $countryId) {
      ...AdminRegionFields
    }
  }
  ${RegionFields}
`;

export const regions = buildQuery({
  listQuery,
  singleItemQuery,
  createQuery,
  updateQuery,
});
