import { Record } from 'react-admin';

import { StudentUserQuery, allStudentUsersQuery } from 'apollo/schema';

import { UserRecord } from '../Users';

/** The Student object returned from the `allStudentUsers` query. */
export type ListQueryStudentRecord = NonNullable<
  NonNullable<allStudentUsersQuery>['items']
>[number] &
  Record;

/** The Student object returned by the `StudentUser` query. */
export type StudentRecord = NonNullable<StudentUserQuery['mono']> & Record;

/** The StudentProfile object from the `StudentUser` queryStudent object. */
export type StudentRecordProfile = NonNullable<
  NonNullable<StudentRecord['user']>['studentProfile']
>;

/**
 * Type guard function to determine if record is StudentRecord or UserRecord
 */
export const isStudentRecord = (
  record: StudentRecord | UserRecord,
): record is StudentRecord => 'user' in record;

/**
 * Type guard function to determine if record is isListQueryStudentRecord
 */
export const isListQueryStudentRecord = (
  record: StudentRecord | UserRecord | ListQueryStudentRecord,
): record is StudentRecord =>
  record?.user && !('studentProfile' in record.user);
